import React, { Component } from 'react';
import '../assets/static/css/preload.css'
import '../assets/static/css/plugins.css'
// import '../assets/static/css/style.light-blue-500.css';
import '../assets/static/css/style.yellow-700.min.css';
import '../assets/styles/App.css';

import Menu from './Menu'; 
import Header from './Header';
import Portfolio from './Porfolio';
import Aside from './Aside';
import VideoGallery from './VideoGallery';

class App extends Component {
    
  render(){
    return (
      <div>
      <div id="ms-preload" className="ms-preload">
      <div id="status">
        <div className="spinner">
          <div className="dot1"></div>
          <div className="dot2"></div>
        </div>
      </div>
    </div>
      <div className="ms-site-container"> 
        <Menu />
        <Header /> 
        <Portfolio />
        <VideoGallery />
        <Aside />
    </div>
    </div>
    );
  }
}

export default App;

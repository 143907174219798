import React from 'react'

import ImgFl1 from '../assets/static/img/wdigital/marketing-digital/flyer01.png'
import ImgFl2 from '../assets/static/img/wdigital/marketing-digital/flyer02.png'
import ImgVs2 from '../assets/static/img/wdigital/marketing-digital/vs2.png'

const Portfolio = () => (
    <div className="container mt-6">
        <h2 className="text-center color-dark mb-2 wow fadeInDown animation-delay-4">DESCUBRA TODO LO QUE <strong>WEST DIGITAL</strong> TIENE PARA OFRECERLE</h2>
        <div className="row">
          <div className="col-md-4 col-sm-6 mb-4">
            <div className="ms-thumbnail-container wow fadeInUp">
              <figure className="ms-thumbnail ms-thumbnail-top ms-thumbnail-info">
                <img src={ImgVs2} alt="" className="img-responsive" />
                <figcaption className="ms-thumbnail-caption text-center">
                  <div className="ms-thumbnail-caption-content">
                    {/* <h3 className="ms-thumbnail-caption-title">Lorem ipsum dolor sit</h3> */}
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
                    {/* <a href="javascript:void(0)" className="btn btn-raised btn-danger">
                      <i className="zmdi zmdi-eye"></i> View more</a> */}
                  </div>
                </figcaption>
              </figure>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <div className="ms-thumbnail-container wow fadeInUp">
              <figure className="ms-thumbnail ms-thumbnail-top ms-thumbnail-info">
                <img src={ImgFl1} alt="" className="img-responsive" />
                <figcaption className="ms-thumbnail-caption text-center">
                  <div className="ms-thumbnail-caption-content">
                    {/* <h3 className="ms-thumbnail-caption-title">Lorem ipsum dolor sit</h3> */}
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
                    {/* <a href="javascript:void(0)" className="btn btn-raised btn-danger">
                      <i className="zmdi zmdi-eye"></i> View more</a> */}
                  </div>
                </figcaption>
              </figure>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <div className="ms-thumbnail-container wow fadeInUp">
              <figure className="ms-thumbnail ms-thumbnail-top ms-thumbnail-info">
                <img src={ImgFl2} alt="" className="img-responsive" />
                <figcaption className="ms-thumbnail-caption text-center">
                  <div className="ms-thumbnail-caption-content">
                    {/* <h3 className="ms-thumbnail-caption-title">Lorem ipsum dolor sit</h3> */}
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
                    {/* <a href="javascript:void(0)" className="btn btn-raised btn-danger">
                      <i className="zmdi zmdi-eye"></i> View more</a> */}
                  </div>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div> 
)

export default Portfolio; 
import React from 'react'

const VideoGallery = () => {
    return (
        <section class="wrap ms-hero-img-team ms-hero-bg-dark-light ms-bg-fixed">
            <div class="text-center color-whit index-1">
            {/* <h1 class="no-m ms-site-title center-block ms-site-title-lg mt-2 animated zoomInDown animation-delay-5">Alarma Vecinal (Demo)</h1> */}
            {/* <p class="lead lead-lg color-light text-center center-block mt-2 mw-800 text-uppercase fw-300 animated fadeInUp animation-delay-7">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam quas, ad necessitatibus a tenetur laborum odio ab sunt suscipit.</p> */}
            </div>
            <div class="container mt-6">
                <div class="row index-1 color-dark d-flex justify-content-center">
                    <div class="col-lg-12 col-md-12">
                        <div class="card card-warning wow zoomInUp animation-delay-5">
                            <div class="text-center bg-warning">
                            <h4><strong>Alarma Vecinal (Demo)</strong></h4>
                        </div>
                        <div class="card-body overflow-hidden text-center">                            
                            <div class="js-player" data-plyr-provider="youtube" data-plyr-embed-id="XjyA7Rjd70Q"></div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            <div class="text-center index-1 mt-2">
            {/* <a href="javascript:void(0)" class="btn btn-danger btn-xlg btn-raised"><i class="zmdi zmdi-accounts"></i> All Clients</a> */}
        </div>
      </section>     
    )
};

export default VideoGallery; 
import React from 'react'; 

import LogoIcon from '../assets/static/img/wdigital/logo-wdigital.png'
import LogoLetras from '../assets/static/img/wdigital/logo-wdigital-whith-words.png'

 const Menu = () => (
    <nav className="navbar navbar-expand-md  navbar-static ms-navbar ms-navbar-prymary navbar-mode">
        <div className="container container-full">
          <div className="navbar-header">
            <a className="navbar-brand" href="index.html">
              <img src={LogoIcon} alt="" width="65" height="40" />
              <img src={LogoLetras} alt="" width="100" height="40" />
            </a>
          </div>
          <div className="collapse navbar-collapse" id="ms-navbar">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a href="#" className="nav-link fadeIn animation-delay-7" role="button"  data-name="home">Home</a>
              </li>
            </ul>
          </div>
          <a href="#" onClick={ e => e.preventDefault() } className="ms-toggle-left btn-navbar-menu"><i className="zmdi zmdi-menu"></i></a>
        </div>
      </nav>
 );

 export default Menu; 
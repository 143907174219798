import React from 'react'

import ImgPhone from '../assets/static/img/wdigital/demo/pixel1C.png'
import ImgPhone2 from '../assets/static/img/wdigital/demo/pixel1A.png'
import ImgPhone3 from '../assets/static/img/wdigital/demo/pixel2B.png'

const Header = () => (
  <header className="ms-hero ms-hero-material mt--40">
  <div className="container">
    <div className="row">
      <div className="col-lg-7 pr-6">
        <h2 className="animated fadeInDown animation-delay-5 color-white"><strong>NUESTRO PRINCIPIO FUNDAMENTAL ES EL RESPETO Y EL COMPROMISO CON NUESTROS CLIENTES.</strong></h2>
        {/* <p className="lead animated fadeInDown animation-delay-5 color-white mb-2"><strong>NUESTRO PRINCIPIO FUNDAMENTAL ES EL RESPETO Y EL COMPROMISO CON NUESTROS CLIENTES.</strong></p> */}
        <div className="card card-body card-warning card-dark-inverse animated fadeInLeft animation-delay-5">
          <div className="media">
            <div className="media-left d-none d-sm-block media-middle pr-4">
              <i className="zmdi zmdi-info zmdi-hc-5x color-warning"></i>
            </div>
            <div className="media-body no-mt">
              <h3 className="color-warning no-mt"><strong>¡IMPORTANTE! (COVID-19)</strong></h3>
              <p>El equipo de WESTDIGITAL está muy atento a las medidas de salud pública tomadas recientemente.</p>
              <p>Estamos tomando todos los recaudos necesarios para continuar con nuestras tareas diarias.</p>
              <p>También estamos asesorando y respondiendo a todas la consultas que nos hagan a nuestro WhatsApp y correo electrónico.</p>
              <p>GRACIAS</p>
            </div>
          </div>
        </div>
        {/* <div className="card card-body card-success card-dark-inverse animated fadeInLeft animation-delay-7">
          <div className="media">
            <div className="media-left d-none d-sm-block media-middle pr-4">
              <i className="zmdi zmdi-phone zmdi-hc-5x color-success"></i>
            </div>
            <div className="media-body no-mt">
              <h3 className="color-success no-mt"><strong>CONTACTENOS</strong></h3>
              <p>WhatsApp: +54 9 11 54 1234 432.</p>
              <p>Email: info@westdigital.com.ar</p>
            </div>
          </div>
        </div> */}
      </div>
      <div className="col-lg-5 text-center mt-6">
        <div className="img-phone-container">
          <img className="img-fluid animated zoomInDown animation-delay-6 index-1" src={ImgPhone} />
          <img className="img-fluid img-phone-left" src={ImgPhone2} />
          <img className="img-fluid img-phone-right" src={ImgPhone3} />
        </div>
      </div>
    </div>
  </div>
</header>
)

export default Header; 
import React from 'react'

import LogoIcon from '../assets/static/img/wdigital/logo-wdigital.png'
import LogoLetras from '../assets/static/img/wdigital/logo-wdigital-whith-words.png'

const Aside = () => (
    <div>
        <aside className="ms-footbar">
            <div className="container">
            <div className="row">
                <div className="col-lg-4 ms-footer-col">
                <div className="ms-footbar-block">
                    <h3 className="ms-footbar-title">Suscribirse</h3>
                    <p className="">Recibí las novedades que WestDigital tiene para ofrecerte. </p>
                    <form>
                    <div className="form-group label-floating mt-2 mb-1">
                        <div className="input-group ms-input-subscribe">
                        <label className="control-label" htmlFor="ms-subscribe"><i className="zmdi zmdi-email"></i> Email</label>
                        <input type="email" id="ms-subscribe" className="form-control" />
                        </div>
                    </div>
                    <button className="ms-subscribre-btn" type="button">Suscribirse</button>
                    </form>
                </div>
                </div>
                <div className="col-lg-5 col-md-7 ms-footer-col ms-footer-alt-color">
                    <div className="ms-footbar-block">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3281.347006462398!2d-58.7353110847686!3d-34.67119058044201!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcbf1e858bc017%3A0xcb4cd1f274d8edbd!2sWest%20Digital%20Soluciones!5e0!3m2!1ses-419!2sar!4v1590810364654!5m2!1ses-419!2sar" width="100%" height="250" frameborder="0"  allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    </div>
                </div>
                <div className="col-lg-3 col-md-5 ms-footer-col ms-footer-text-right">
                <div className="ms-footbar-block">
                    <div className="ms-footbar-title">
                        <img src={LogoIcon} alt="" width="65" height="40" />
                        <img src={LogoLetras} alt="" width="100" height="40" />
                    </div>
                    <address className="no-mb">
                    <p><i className="color-danger-light zmdi zmdi-pin mr-1"></i> Mendoza 505 </p>
                    <p><i className="color-warning-light zmdi zmdi-map mr-1"></i> Merlo, Buenos Aires, CP 1722</p>
                    <p><i className="color-info-light zmdi zmdi-email mr-1"></i> <a href="mailto:info@westdigital.com.ar">info@westdigital.com.ar</a></p>
                    <p><i className="color-royal-light zmdi zmdi-phone mr-1"></i>+54 11 6202 3341 </p>
                    {/* <p><i className="color-success-light fa fa-fax mr-1"></i>+34 123 456 7890 </p> */}
                    </address>
                </div>
                {/* <div className="ms-footbar-block">
                    <h3 className="ms-footbar-title">Social Media</h3>
                    <div className="ms-footbar-social">
                    <a href="javascript:void(0)" className="btn-circle btn-facebook"><i className="zmdi zmdi-facebook"></i></a>
                    <a href="javascript:void(0)" className="btn-circle btn-twitter"><i className="zmdi zmdi-twitter"></i></a>
                    <a href="javascript:void(0)" className="btn-circle btn-youtube"><i className="zmdi zmdi-youtube-play"></i></a><br/>
                    <a href="javascript:void(0)" className="btn-circle btn-google"><i className="zmdi zmdi-google"></i></a>
                    <a href="javascript:void(0)" className="btn-circle btn-instagram"><i className="zmdi zmdi-instagram"></i></a>
                    <a href="javascript:void(0)" className="btn-circle btn-github"><i className="zmdi zmdi-github"></i></a>
                    </div>
                </div> */}
                </div>
            </div>
            </div>
        </aside>
        <footer className="ms-footer">
            <div className="container">
            <p>Copyright &copy; WestDigital 2020 </p>
            </div>
        </footer>
    </div>
)

export default Aside; 

